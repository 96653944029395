import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Wrapper,
    ContentWrapper,
    Column,
    ImageWrapper,
    Image,
    PersonaTypeText,
    Message,
    Caption,
    ButtonWrapper,
} from './CongratulationsStyles';
import Button from '../Button/Button';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { User } from '../../utils/types';

/**
 * Congratulations component displays a congratulatory message and actions based on the persona type.
 *
 * @returns {JSX.Element} The rendered Congratulations component.
 */
const Congratulations: React.FC = () => {
    const { t } = useTranslation();
    const { getLocalStorageItem } = useLocalStorage<User>('user', {} as User);
    const user = getLocalStorageItem();

    /**
     * Memoized lowercase version of the persona type.
     */
    const personaTypeLowerCase = useMemo(
        () => user?.personaType?.toLowerCase() || '',
        [user]
    );

    /**
     * Handles the download button click event.
     * Downloads an image based on the persona type and language.
     */
    const handleDownloadClick = useCallback(() => {
        if (!personaTypeLowerCase) {
            console.error('Persona type is not defined');
            return;
        }

        const language = user?.language || 'en';
        const fileName = `${personaTypeLowerCase}.png`;
        const filePath = `${window.location.origin}/images/downloadables/${language}/${fileName}`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [personaTypeLowerCase, user]);

    /**
     * Handles the share button click event.
     * Opens the share URL in a new tab.
     */
    const handleShareClick = useCallback(() => {
        const shareUrl = 'https://alive.abbott/channels/1521870';
        window.open(shareUrl, '_blank');
    }, []);

    return (
        <Wrapper>
            <ContentWrapper>
                <Column>
                    <ImageWrapper>
                        <Image
                            src={`${window.location.origin}/images/medals/${personaTypeLowerCase}.png`}
                            alt={user?.personaType || ''}
                        />
                        <PersonaTypeText $personaType={personaTypeLowerCase}>
                            {personaTypeLowerCase
                                ? t(
                                      `congratulations.${personaTypeLowerCase}.persona`
                                  )
                                : ''}
                        </PersonaTypeText>
                    </ImageWrapper>
                </Column>
                <Column>
                    <Message>
                        {personaTypeLowerCase
                            ? t(
                                  `congratulations.${personaTypeLowerCase}.message`
                              )
                            : ''}
                    </Message>
                    <Caption>{t('congratulations.caption')}</Caption>
                </Column>
            </ContentWrapper>
            <ButtonWrapper>
                <Button onClick={handleDownloadClick}>
                    {t('congratulations.buttons.download')}
                </Button>
                <Button onClick={handleShareClick}>
                    {t('congratulations.buttons.share')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default React.memo(Congratulations);
