import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    overflow-y: auto;
`;

export const Question = styled.h2`
    color: #002a3a;
    text-align: center;
    font-family: 'Brandon Grotesque Bold';
    font-size: 2.4375rem;
    font-style: normal;
    font-weight: 450;
    line-height: 120%;
    margin: 1.5rem 0 3rem;

    @media (max-width: 1024px) {
        font-size: 150%;
        margin: 0.75rem 0 1.5rem;
    }
`;

export const ButtonsContainer = styled.div`
    margin-bottom: 2.43rem;
`;
