import styled from 'styled-components';

export const StepListWrapper = styled.div`
    position: absolute;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1000;

    @media (max-width: 445px) {
        top: 6px;
    }

    @media (min-width: 446px) and (max-width: 1440px) {
        top: -68px;
    }

    @media (min-width: 1441px) {
        top: -68px;
    }
`;

export const PathImage = styled.img<{
    $enabled: boolean;
    $top: number;
    $left: number;
    width: number;
    height: number;
}>`
    position: absolute;
    opacity: ${(props) => (props.$enabled ? 1 : 0)};
    transition: opacity 0.3s;
    top: ${({ $top }) => $top}px;
    left: ${({ $left }) => $left}px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
`;

export const FlagImage = styled.img`
    position: absolute;
    width: 50px;
    height: auto;
    top: 208px;
    left: 147px;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
`;

export const SVGWrapper = styled.svg`
    position: absolute;
    top: 46px;
    left: 18px;
    z-index: 1;
`;

export const SVGPath = styled.path`
    stroke: #470a68;
    stroke-width: 4px;
    fill: none;
    stroke-dasharray: 8 8;
    stroke-linecap: round;
`;
