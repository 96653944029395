/**
 * Predefined positions for markers.
 *
 * @type {{ top: number, left: number }[]}
 * @property {number} top - The top position of the marker in pixels.
 * @property {number} left - The left position of the marker in pixels.
 */
export const predefinedMarkers = [
    { top: 656, left: 54 },
    { top: 555, left: 180 },
    { top: 472, left: 100 },
    { top: 405, left: 197 },
    { top: 325, left: 105 },
    { top: 256, left: 124 },
];

/**
 * Predefined paths with their respective positions and dimensions.
 *
 * @type {{ src: string, top: number, left: number, width: number, height: number }[]}
 * @property {string} src - The source URL of the path image.
 * @property {number} top - The top position of the path image in pixels.
 * @property {number} left - The left position of the path image in pixels.
 * @property {number} width - The width of the path image in pixels.
 * @property {number} height - The height of the path image in pixels.
 */
export const predefinedPaths = [
    {
        src: `${window.location.origin}/images/various/paths/path1.svg`,
        top: 699,
        left: 47,
        width: 83.07,
        height: 86.5,
    },
    {
        src: `${window.location.origin}/images/various/paths/path2.svg`,
        top: 588,
        left: 46,
        width: 172.5,
        height: 128.14,
    },
    {
        src: `${window.location.origin}/images/various/paths/path3.svg`,
        top: 512,
        left: 101,
        width: 113.5,
        height: 118,
    },
    {
        src: `${window.location.origin}/images/various/paths/path4.svg`,
        top: 445,
        left: 112,
        width: 123.5,
        height: 92,
    },
    {
        src: `${window.location.origin}/images/various/paths/path5.svg`,
        top: 371,
        left: 124,
        width: 115,
        height: 102,
    },
    {
        src: `${window.location.origin}/images/various/paths/path6.svg`,
        top: 311,
        left: 110,
        width: 37,
        height: 54.5,
    },
];
