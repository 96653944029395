import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, Title, Description } from './FeedbackPopupStyles';

interface FeedbackPopupProps {
    onClose: () => void;
    stepNumber: number;
    duration?: number;
}

/**
 * FeedbackPopup component displays a feedback message for a specific duration.
 *
 * @param {FeedbackPopupProps} props - The props for the FeedbackPopup component.
 * @param {() => void} props.onClose - Function to call when the popup closes.
 * @param {number} props.stepNumber - The current step number in the quiz.
 * @param {number} [props.duration=5000] - The duration for which the popup should be displayed (default is 5000ms).
 * @returns {JSX.Element} The rendered FeedbackPopup component.
 */
const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
    onClose,
    stepNumber,
    duration = 3000,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(onClose, duration);
        return () => clearTimeout(timer);
    }, [onClose, duration]);

    const renderDescription = useMemo(() => {
        return (
            <>
                <Title data-testid="feedback-title">
                    {t(`quiz.steps.${stepNumber}.feedback.title`)}
                </Title>
                <Description data-testid="feedback-description">
                    {t(`quiz.steps.${stepNumber}.feedback.description`)}
                </Description>
            </>
        );
    }, [stepNumber, t]);

    return <Popup $duration={duration}>{renderDescription}</Popup>;
};

export default React.memo(FeedbackPopup);
